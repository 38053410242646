import logo from "../src/assets/images/brands/logo.jpg";
import sLogo from "../src/assets/images/brands/smallLogo.jpg";
import bigLogo from "../src/assets/images/brands/icare-logo-big.png";

export const clientName = "Aravindan Manoharan Sivakumari";
export const developedBy = "Aravindan Manoharan Sivakumari";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "Aravindan Manoharan Sivakumari";
export const sidebarLogo = logo;
export const smallLogo = sLogo;
export const mainLogo = bigLogo;
export const lightLogo = logo;